import { graphql } from "gatsby";
import parse, { domToReact } from "html-react-parser";
import React, { Component } from "react";
import Footer from "../components/Footer";
import Header from "../components/header";
import HeroBackground from "../components/HeroBackground";
import SEO from "../components/seo";

const options = {
  replace: (domNode) => {
    let { attribs, children, name } = domNode;

    if (name === "h2") {
      return (
        <h2 className="text-4xl font-display  text-gray-900 mt-6 font-semibold">
          {domToReact(children, options)}
        </h2>
      );
    }

    if (name === "h3") {
      return (
        <h3 className="text-3xl font-display font-semibold">
          {domToReact(children, options)}
        </h3>
      );
    }

    if (name === "h4") {
      return (
        <h4 className="text-2xl font-display font-semibold">
          {domToReact(children, options)}
        </h4>
      );
    }

    if (name === "p") {
      return (
        <p className="text-base mt-4 break-words">
          {domToReact(children, options)}
        </p>
      );
    }

    if (name === "a") {
      return (
        <a
          className="no-underline hover:underline text-gladior-pink"
          {...attribs}
        >
          {domToReact(children, options)}
        </a>
      );
    }

    if (name === "ul") {
      return (
        <ul className="list-disc list-inside break-words">
          {domToReact(children, options)}
        </ul>
      );
    }

    if (attribs && attribs.class === "wp-block-columns" && name === "div") {
      return (
        <div className="flex items-stretch flex-row flex-wrap">
          {domToReact(children, options)}
        </div>
      );
    }

    if (attribs && attribs.class === "wp-block-column" && name === "div") {
      return <div className="flex-1 py-8">{domToReact(children, options)}</div>;
    }

    if (attribs && name === "blockquote") {
      return (
        <blockquote
          className={
            "p-4 bg-neutral-100 text-neutral-600 border-l-4 border-neutral-500 italic relative quote m-5"
          }
        >
          <div className="mb-4"> {domToReact([children[0]], options)}</div>
          {children.length > 1 && (
            <cite className="flex items-center">
              <div className="flex flex-col items-start">
                {domToReact([children[1]], options)}
              </div>
            </cite>
          )}
        </blockquote>
      );
    }
  },
};

class Page extends Component {
  render() {
    const StaticPage = this.props.data.wordpressPage;
    return (
      <React.Fragment>
        <SEO
          title={StaticPage.yoast_meta.yoast_wpseo_title}
          description={StaticPage.yoast_meta.yoast_wpseo_metadesc}
          pathname={StaticPage.path}
        />

        <div className="flex flex-col font-sans min-h-screen text-gray-900">
          <Header />
          <HeroBackground>
            <section className="w-full  mt-32  flex flex-col flex-1 md:justify-center">
              <section className="text-white flex flex-col justify-center content-center items-center max-w-5xl  container mx-auto  text-center my-8 h-full md:h-40">
                {!StaticPage.path.startsWith("/privacy") &&
                  !StaticPage.path.startsWith("/voorwaarden") &&
                  !StaticPage.path.startsWith("/archief") && (
                    <span className="flex rounded-full bg-gladior-pink uppercase px-2 py-1 text-xs font-bold mr-3 font-body">
                      Archief
                    </span>
                  )}
                <h1 className=" mt-2  text-4xl leading-tight xl:text-5xl font-semibold font-display text-white">
                  {StaticPage.title}
                </h1>
                <p className="mt-3 text-lg max-w-xl lg:max-w-3xl text-gray-400 xl:text-2xl font-display ">
                  {StaticPage.wps_subtitle}
                </p>
              </section>
            </section>
          </HeroBackground>
          <main className="container mx-auto px-6 py-10  max-w-5xl ">
            {parse(StaticPage.content, options)}
          </main>
          <Footer isDark={true} />
        </div>
      </React.Fragment>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      title
      wps_subtitle
      content
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
