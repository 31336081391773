import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";

function HeroBackground({ children, heroBackgroundImage }) {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "gladior-team.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  const { desktop } = data;

  // Single Image Data
  const imageData = heroBackgroundImage
    ? heroBackgroundImage.childImageSharp.fluid
    : desktop.childImageSharp.fluid;

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      fadeIn={false}
      style={{
        backgroundBlendMode: "multiply",
        backgroundPosition: "center",
      }}
      preserveStackingContext={true}
      opacity={0.7}
      aria-label="Fullscreen Background"
      backgroundColor={`#53575d`}
    >
      {children}
    </BackgroundImage>
  );
}

export default HeroBackground;
